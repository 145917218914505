<div class="header">
    <ng-content select="vsf-layout-header"></ng-content>
</div>
<div class="content">
    <ng-content></ng-content>
</div>
<div class="footer">
    <div class="container">
        <ng-content select="vsf-layout-footer"></ng-content>
    </div>
</div>
