<div class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header" [ngClass]="options.type">
        <strong class="mr-auto">{{ options.title }}</strong>
        <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close" (click)="close.next()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="toast-body">
        <ng-container *ngIf="options.message">
            {{ options.message }}
        </ng-container>
        <ng-container *ngIf="options.templateRef">
            <ng-container *ngTemplateOutlet="options.templateRef; context: options.templateContext"></ng-container>
        </ng-container>
    </div>
</div>
